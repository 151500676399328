const CATEGORY =  {
    DEVICE: "Device",
    ENROLMENT: "Enrolment",
    NETWORK: "Network",
    SIM: "Sim",
    OS: "Os",
    APPLICATION: "Application",
    ANDROID_API_MANAGEMENT: "Android API Management"
};

export default {
    CATEGORY,
    HEADERS: [
        { text: 'Id Device', value: 'name', col: 'name', default: true, selected: false, category: null },
        { text: 'User', value: 'user', col: 'connected_user', default: true, selected: false, category: null},
        { text: 'Last Check', value: 'dateLastCheck.value', col: 'date_last_check', default: true, selected: false, category: null},
        { text: 'Serial Number', value: 'serialNumber', col: 'serial_number', default: false, selected: true, category: CATEGORY.DEVICE},
        { text: 'Type', value: 'category', col: 'device_type', default: false, selected: true, category: CATEGORY.DEVICE},
        { text: 'Manufacturer', value: 'manufacturer', col: 'manufacturer', default: false, selected: true, category: CATEGORY.DEVICE},
        { text: 'Model', value: 'model', col: 'model', default: false, selected: true, category: CATEGORY.DEVICE},
        { text: 'Business Unit Bu', value: 'entity', col: 'entity', default: false, selected: true, category: CATEGORY.ENROLMENT},
        { text: 'Enrolled By', value: 'enrolledBy', col: 'enrolled_by', default: false, selected: false, category: CATEGORY.ENROLMENT},
        { text: 'Site', value: 'siteId', col: 'site_id', default: false, selected: true, category: CATEGORY.ENROLMENT},
        { text: 'Country', value: 'country', col: 'country', default: false, selected: true, category: CATEGORY.ENROLMENT },
        { text: 'Enrolment Date', value: 'dateEnrolment', col:'date_enrolment', default: false, selected: false, category: CATEGORY.ENROLMENT },
        { text: 'Agent', value: 'agent', col:'agent', default: false, selected: false, category: CATEGORY.ENROLMENT },
        { text: 'Ownership', value: 'displayPerso', col:'enterprise', default: false, selected: false, category: CATEGORY.ENROLMENT },
        { text: 'IP Adress', value: 'ip', col:'ip', default: false, selected: false, category: CATEGORY.NETWORK },
        { text: 'Mac Adress', value: 'mac', col:'mac', default: false, selected: false, category: CATEGORY.NETWORK },
        { text: 'Application Name', value: 'application', col:'application', default: false, selected: false, category: CATEGORY.APPLICATION },
        { text: 'Version', value: 'version', col:'version', default: false, selected: false, category: CATEGORY.APPLICATION },
        { text: 'IMEI', value: 'imeiValue', col:'imei_value', default: false, selected: false, category: CATEGORY.DEVICE },
        { text: 'Phone number', value: 'phoneNumber', col:'phone_number', default: false, selected: false, category: CATEGORY.SIM },
        { text: 'SIM number', value: 'simNumber', col:'sim_number', default: false, selected: false, category: CATEGORY.SIM },
        { text: 'OS', value: 'os', col:'os' , default: false, selected: false, category: CATEGORY.OS },
        { text: 'OS Version', value: 'osVersion', col:'os_version', default: false, selected: false, category: CATEGORY.OS },
        { text: 'Build version', value: 'buildVersion', col:'build_version', default: false, selected: false, category: CATEGORY.OS },
        { text: 'Activation', value: 'androidSilentUpdate', col:'android_silent_update', default: false, selected: false, category: CATEGORY.ANDROID_API_MANAGEMENT },
        { text: 'Wifi', value: 'wifi', col:'wlan', default: false, selected: false, category: CATEGORY.NETWORK }

    ],
    DEFAULT_MOBILE_HEADERS: [
        { text: 'Id Device', value: 'name', col: 'name'},
        { text: 'Site', value: 'siteId', col: 'site_id'},
        { text: 'Country', value: 'country', col: 'country'},
        { text: 'Type', value: 'category', col: 'device_type'},
        { text: 'Model', value: 'model', col: 'model'},
        { text: 'User', value: 'user', col: 'connected_user'},
    ],
    MIGRATION_HEADERS: [
        { text: 'Serial number', value: 'serialNumber', col: 'serialNumber', default: false, selected: true, category: null},
        { text: 'Type', value: 'deviceType', col: 'deviceType', default: true, selected: true, category: null},
        { text: 'Ownership', value: 'isProDevice', col: 'isProDevice', default: false, selected: true, category: null},
        { text: 'Country', value: 'country', col: 'country', default: false, selected: true, category: null},
        { text: 'Site', value: 'siteId', col: 'siteId', default: false, selected: true, category: null},
        { text: 'WIFI', value: 'wlan', col: 'wlan', default: false, selected: true, category: null},
        { text: 'MDM Status', value: 'mdmStatus', col: 'mdmStatus', default: false, selected: true, category: null},
        { text: 'WSO Migration Status', value: 'wsoStatus', col: 'wsoStatus', default: false, selected: true, category: null},
        { text: 'Wipe Date', value: 'wipeDate', col: 'wipeDate', default: false, selected: true, category: null},
        { text: 'Last Check', value: 'dateLastCheck.value', col: 'dateLastCheck', default: false, selected: true, category: null},
    ],
    DEFAULT_MOBILE_MIGRATION_HEADERS: [
        { text: 'Serial number', value: 'serialNumber', col: 'serialNumber', default: false, selected: true, category: null},
        { text: 'Site', value: 'siteId', col: 'siteId', default: false, selected: true, category: null},
        { text: 'MDM Status', value: 'mdmStatus', col: 'mdmStatus', default: false, selected: true, category: null},
        { text: 'WSO Migration Status', value: 'wsoStatus', col: 'wsoStatus', default: false, selected: true, category: null},
        { text: 'Wipe Date', value: 'wipeDate', col: 'wipeDate', default: false, selected: true, category: null},
        { text: 'Last Check', value: 'dateLastCheck.value', col: 'dateLastCheck', default: false, selected: true, category: null},
    ]
}
