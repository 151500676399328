import Vue from 'vue';
import {store} from "../../store/store";
import Moment from 'moment';
import ArboUtils from '../../utils/arbo-utils'
import Constant from '../../utils/field-constant';
import DeploymentGroupConstant from "../../constant/deployment-group-constant";
import appTypeEnum from "../application/app-type-enum";
import applicationServices from "../application/application-service";
import PublicApplicationServices from "../application/public-application-services";
import VersionService from "../version-service";

const camelcaseKeys = require('camelcase-keys');

export default {
    isBuServices(idGroup, path) {
        return idGroup === DeploymentGroupConstant.BU.SERVICES.toLowerCase() || path?.endsWith(DeploymentGroupConstant.BU.SERVICES.toLowerCase());
    },
    async forceUpdateAndroidPoliciesForGroupId(groupId) {
        return Vue.http.get(
            `${store.state.getUrlWithParams(store.state.urlNotifyAllAndroidPoliciesForGroupId, {groupId})}`
        );
    },

    async saveGroup(group) {
        const jsonData = JSON.stringify(this.toSend(group));

        const response = await Vue.http.patch(store.state.urlGroupSave, jsonData);

        if (group.id) {
            let gaEvent = Object.assign({}, this.toSend(group));
            Vue.$ga.event({eventCategory: 'group', eventAction: 'update', eventLabel: JSON.stringify(gaEvent)});
        } else {
            Vue.$ga.event({eventCategory: 'group', eventAction: 'create', eventLabel: JSON.stringify(jsonData)});
        }

        return response;
    },

    toSend(group) {
        return {
            id: group.id,
            name: group.name,
            activationDate: Moment(group.schedule || new Date()).format('x'),
            priority: 1,
            availability: group.availability,
            requirement_json: {...buildRequirementJson(group)},
            application_id: group.application.id,
            version_id: group.version.id,
            active: group.activated,
            bu: group.bu,
            package_name: group.application.uid,
            app_type: group.application.appType
        }
    },

    async countUser(group, onlyArbo = false) {
        return await Vue.http.post(store.state.urlGroupDeviceCount + "?details=false", JSON.stringify({requirement: buildRequirementJson(group, onlyArbo)}));
    },

    async getGroup(idGroup, needArbo = true) {
        const response = await Vue.http.get(store.state.getUrlWithParams(store.state.urlGroupId, {idGroup}));

        return {
            ...camelcaseKeys(response.data, {deep: true}),
            arbo: needArbo ? await ArboUtils.transformRequirementToArbo(response.data.requirement_json) : {}
        }
    },

    async getStats(group) {
        if (!group.activated) return;

        const response = await Vue.http.get(store.state.getUrlWithParams(store.state.urlGroupState, {groupId: group.id}));

        if (response.status === 204) return;

        let groupStats = response.body;
        let compliance = ((groupStats.devices * 100) / groupStats.total).toFixed(1);

        if (groupStats.total == 0 && groupStats.devices == 0) compliance = 100;
        else if (compliance == 'NaN' || compliance == Infinity) compliance = 0;
        else if (compliance > 100) compliance = 100;

        return {
            groupId: group.id,
            state: {
                country: groupStats.countries,
                site: groupStats.sites,
                device: groupStats.devices,
                deviceOk: compliance,
                total: groupStats.total
            }
        };
    },

    async getAdvancedStats(groupId) {
        const response = await Vue.http.get(store.state.getUrlWithParams(store.state.urlGroupAdvancedState, {groupId}));
        if (response.status === 204) throw new Error("No data available for this group");

        let groupStats = camelcaseKeys(response.body, {deep: true});

        return {
            updateSince: {
                threeMonths: computedAllPourcent(groupStats.updateSince.threeMonths),
                groupUpdate: computedAllPourcent(groupStats.updateSince.groupUpdate)
            }
        }
    },
    async getAvailableVersion(appToSearch, isBuServices = false) {
        try {
            let searchabledVersion;
            let version = {};
            if (appToSearch.appType === appTypeEnum.PRIVATE_APP && isBuServices) {
                let productionVersionCode = await (await Vue.http.get(store.state.getUrlWithParams(store.state.urlApplicationVersionCode, {packageName: appToSearch.uid}))).body;
                searchabledVersion = [{version: productionVersionCode}];
                version = store.dispatch('group/setGroup', this.buildSpecificVersion(appToSearch, productionVersionCode, `production (version code ${productionVersionCode})`, isBuServices));
            }
            if (appToSearch.appType === appTypeEnum.PUBLIC_APP) {
                const applicationPlaystore = await PublicApplicationServices.getApplicationInformation(appToSearch.uid);
                searchabledVersion = [{version: applicationPlaystore.version}];
                version = this.buildSpecificVersion(appToSearch, applicationPlaystore.version, applicationPlaystore.version);
            } else if (appToSearch.appType === appTypeEnum.WEBVIEW) {
                const webview = await applicationServices.getWebviewInformation(appToSearch.uid);
                searchabledVersion = [{version: this.PRODUCTION_VERSION_NAME}];
                version = store.dispatch('group/setGroup', this.buildSpecificVersion(appToSearch, webview.versionCode + "", this.PRODUCTION_VERSION_NAME));
            } else {
                const availableVersions = await VersionService.getAvailableInformationOnVersion(appToSearch.versions);

                searchabledVersion = appToSearch.versions?.filter(version => {
                    const isVersionAvailable = availableVersions.find(availableVersion => (availableVersion.exists && availableVersion.s3FilePath === version.filePath));
                    const hasRightOnTheVersion = version.eligibility.indexOf(store.state.user.country) > -1 || store.state.user.admin;
                    return hasRightOnTheVersion && isVersionAvailable;
                }).map(version => ({...version, version: version.versionName}));
            }

            store.dispatch('group/setGroup', {
                ...version,
                availabledVersions: {
                    loading: false,
                    versions: searchabledVersion
                }
            });
        } catch (e) {
            console.error("Error when loading available version:", e);

            this.$store.dispatch('group/setGroup', {
                availabledVersions: {
                    loading: false,
                    error: true
                }
            });
        }
    },
    buildSpecificVersion(appToSearch, versionCode, versionName, isBuServices) {
        return {
            version: {
                id: this.getVersionId(isBuServices, appToSearch, versionCode),
                appId: appToSearch.id,
                uid: appToSearch.uid,
                available: {available: true},
                debuggable: false,
                eligibility: "",
                versionCode: versionCode,
                versionName: versionName,
                version: versionName
            }
        }
    },
    getVersionId(isBuServices, appToSearch, versionCode) {
        if (isBuServices) {
            return (appToSearch.versions.find(version => version.versionCode === versionCode)).id;
        } else {
            return appToSearch.versions[0]?.id;
        }
    }
}

const computedAllPourcent = (stats) => {
    if (!stats) return null;
    return {
        total: {
            ...stats.total,
            pourcentDs: computedPourcent(stats.total.ds, stats.total.all),
            pourcentAam: computedPourcent(stats.total.aam, stats.total.all)
        },
        upToDate: {
            ...stats.upToDate,
            pourcentDs: computedPourcent(stats.upToDate.ds, stats.total.ds),
            pourcentAam: computedPourcent(stats.upToDate.aam, stats.total.aam),
            pourcentAll: computedPourcent(stats.upToDate.all, stats.total.all)
        }
    }
}

const computedPourcent = (numberToCompute, total) => {
    if (total === 0) return 100;
    return ((numberToCompute * 100) / total).toFixed(1);
}

const buildRequirementJson = (group, onlyArbo = false) => (
    {
        platform: group.application.platform,
        devices: (!onlyArbo && group.includeDeviceCheck && group.requirement.devices) || [],
        deviceType: (!onlyArbo && group.requirement.deviceType.length > 0 && group.requirement.deviceType) || [Constant.DEVICE.TYPE.SMARTPHONE, Constant.DEVICE.TYPE.TABLET, Constant.DEVICE.TYPE.KIOSK],
        models: {
            included: (!onlyArbo && group.includeModelCheck && group.requirement.models.included) || [],
            excluded: (!onlyArbo && group.excludeModelCheck && group.requirement.models.excluded) || []
        },
        ...ArboUtils.transformArboToRequirement(group.arbo)
    }
)
