<template>
    <div>
        <md-progress-spinner v-if="!playstoreLastStatusLoaded" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>

        <span v-else-if="canShowComponent()" class="playstore-logo-container">

            <component :is="track.color + '-playstore-logo'" :id="'group-row-' + track.color + '-playstore-logo-' + groupPrio" />

            <md-tooltip md-direction="top" class="tool-tip-multi-row" :id="'group-row-popin-' + groupPrio">
                <span v-if="playstoreLastStatus.state === PLAYSTORE_STATUS.PUBLIC_APP || playstoreLastStatus.state === PLAYSTORE_STATUS.WEBVIEW" >
                    <p>
                        • This application is a {{playstoreLastStatus.state === PLAYSTORE_STATUS.PUBLIC_APP ? 'public application' : 'webview'}}. <br />
                        • The latest version will be on your device
                    </p>
                </span>
                <span v-else-if="playstoreLastStatus.state === PLAYSTORE_STATUS.DONE
                                || playstoreLastStatus.state === PLAYSTORE_STATUS.NONE">

                    <p v-if="track.color === 'orange'">{{TOOLTIP_NOT_UP_TO_DATE}}<br/></p>
                    <p>
                        • Track ID : {{track.id || UPDATING}}  <br/>
                        • Track Alias : {{track.alias || UPDATING}}  <br/>
                        • Track version code : {{track.psVersionCode || UPDATING}}  <br/>
                        • MDM version code: {{track.mdmVersionCode}}  <br/>
                    </p>
                </span>
                <span v-else-if="playstoreLastStatus.state === PLAYSTORE_STATUS.IN_PROGRESS">

                    <ul>
                        <li v-for="(item, index) in playstoreLastStatusInProgressInfo" :key="index">Step {{ ++index }}: {{ item }}</li>
                    </ul>
                    <span v-if="playstoreLastStatus.error"><br/>• Last error : {{ "(" + playstoreLastStatus.error.code + ") " + playstoreLastStatus.error.message  }}</span>

                </span>
                <span v-else-if="playstoreLastStatus.state === PLAYSTORE_STATUS.ERROR">
                    • Error : {{track.error}}
                </span>
            </md-tooltip>
        </span>
    </div>
</template>

<script>

    import FieldConstant from "../../../utils/field-constant";
    import ApplicationService from "../../../service/application/application-service";
    import appTypeEnum from "../../../service/application/app-type-enum";
    import RedPlaystoreLogo from "../../../assets/icons/red-playstore-logo.vue";
    import OrangePlaystoreLogo from "../../../assets/icons/orange-playstore-logo.vue";
    import GreenPlaystoreLogo from "../../../assets/icons/green-playstore-logo.vue";

    export default {
        name: 'GroupAndroidTrackButton',
        components: {RedPlaystoreLogo, OrangePlaystoreLogo, GreenPlaystoreLogo},
        data() {
            return {
                PLAYSTORE_STATUS: FieldConstant.GROUP.PLAYSTORE_STATUS,
                TOOLTIP_NOT_UP_TO_DATE: FieldConstant.APPLICATION.TOOLTIPS.PLAYSTORE.NOT_UP_TO_DATE,
                playstoreLastStatus: {},
                playstoreLastStatusLoaded: false,
                NOT_FOUND_ON_PLAYSTORE: "Not found on playstore",
                UPDATING: "Updating...",
                TRACKS_REFRESH_DELAY: 5000,
                playstoreLastStatusLoadedOneTime: false,
                playstoreSteps: {
                    PlaystoreCreateTrack: "Play Store - Create Track",
                    PlaystoreSetTrack: "Play Store - Set Track",
                    MdmSetTrack: "MDM - Set Track",
                },
                playstoreStepStatus: {
                    ToDo: "To Do",
                    InProgress: "In Progress",
                    Done: "Done",
                }
            }
        },
        mounted() {
            this.getPlaystoreLastStatus();
        },
        props: {
            androidTrack : {type: Object},
            appVersionCode: {type: Number},
            appUid: {type: String},
            appType: {type: String},
            appId: {type: String},
            groupPrio: {type: String}
        },
        computed: {
            track() {
                return {
                    id: this.androidTrack?.trackId,
                    status: this.playstoreLastStatus?.state,
                    color: this.getIconColor(this.androidTrack),
                    error: this.getStateError(),
                    alias: this.getTrackAlias(this.playstoreLastStatus?.state, this.androidTrack),
                    psVersionCode: this.androidTrack?.versionCode,
                    mdmVersionCode: this.appVersionCode,
                };
            },
            playstoreLastStatusInProgressInfo() {
                const {PlaystoreCreateTrack, PlaystoreSetTrack, MdmSetTrack} = this.playstoreSteps;
                const {ToDo, InProgress, Done} = this.playstoreStepStatus;
                const {current_attempts, total_attempts, step: {name: stepName, detail: stepDetail}} = this.playstoreLastStatus;
                let inProgressInfo = [`• ${PlaystoreCreateTrack}: ${ToDo}`, `• ${PlaystoreSetTrack}: ${ToDo}`, `• ${MdmSetTrack}: ${ToDo}`];

                let retryInfo = `${stepDetail ? stepDetail+': ' : ''}${InProgress}: ${current_attempts}/${total_attempts}`;

                if ( this.track.error ){
                    retryInfo += " | " + this.track.error;
                }

                switch (stepName) {
                    case PlaystoreCreateTrack:
                        inProgressInfo[0] = inProgressInfo[0].replace(`${ToDo}`, retryInfo);
                        break;
                    case PlaystoreSetTrack:
                        inProgressInfo[0] = `${PlaystoreCreateTrack}: ${Done}`;
                        inProgressInfo[1] = inProgressInfo[1].replace(`${ToDo}`, retryInfo);
                        break;
                    case MdmSetTrack:
                        inProgressInfo[0] = `${PlaystoreCreateTrack}: ${Done}`;
                        inProgressInfo[1] = `${PlaystoreSetTrack}: ${Done}`;
                        inProgressInfo[2] = inProgressInfo[2].replace(`${ToDo}`, retryInfo);
                        break;
                }
                return inProgressInfo;
            }
        },
        methods: {
            getTrackAlias(state, trackInfo){
                return trackInfo ?
                    (trackInfo.trackAlias ? trackInfo.trackAlias : this.NOT_FOUND_ON_PLAYSTORE) :
                    (state === this.PLAYSTORE_STATUS.DONE ? this.UPDATING : this.NOT_FOUND_ON_PLAYSTORE);
            },
            getPlaystoreLastStatus(){
                this.playstoreLastStatusLoaded = false;
                if(this.appType === appTypeEnum.PUBLIC_APP) {
                    this.playstoreLastStatus = {state: this.PLAYSTORE_STATUS.PUBLIC_APP};
                    this.playstoreLastStatusLoaded = true;
                }else if (this.appType === appTypeEnum.WEBVIEW) {
                    this.playstoreLastStatus = {state: this.PLAYSTORE_STATUS.WEBVIEW};
                    this.playstoreLastStatusLoaded = true;
                } else {
                    ApplicationService.getPlaystoreLastStatusByTypeAndId("GROUP", this.appId, this.appVersionCode)
                        .then( res => {
                            this.playstoreLastStatus = res;
                            this.playstoreLastStatusLoaded = true;
                            this.callRefreshAndroidTrackIdsIfNeeded();
                        });
                }
            },
            async callRefreshAndroidTrackIdsIfNeeded() {
                if (this.playstoreLastStatusLoadedOneTime){
                    return;
                }
                this.playstoreLastStatusLoadedOneTime = true;

                const interval = setInterval(async () => {
                    if (this.playstoreLastStatus?.state === this.PLAYSTORE_STATUS.IN_PROGRESS
                        || this.playstoreLastStatus?.state === this.PLAYSTORE_STATUS.NONE) {
                        await this.getPlaystoreLastStatus();
                    } else {
                        clearInterval(interval);
                    }
                }, this.TRACKS_REFRESH_DELAY)
            },
            canShowComponent(){
                return this.appType === appTypeEnum.PUBLIC_APP || this.appType === appTypeEnum.WEBVIEW || this.getIconColor(this.androidTrack) != null;
            },
            getIconColor(trackInfo) {
                if(!this.playstoreLastStatusLoaded && this.playstoreLastStatus?.state == null) return null;
                switch(this.playstoreLastStatus.state){
                    case this.PLAYSTORE_STATUS.ERROR:
                    case this.PLAYSTORE_STATUS.NONE:
                        return 'red';
                    case this.PLAYSTORE_STATUS.IN_PROGRESS:
                        return this.playstoreLastStatus.step?.name?.toLowerCase().includes("play store") ? 'orange' : 'brown';
                    case this.PLAYSTORE_STATUS.PUBLIC_APP:
                    case this.PLAYSTORE_STATUS.WEBVIEW:
                        return 'green';
                    default:
                        return this.getDefaultIconColor(trackInfo);
                }

            },
            getDefaultIconColor(trackInfo){
                if ( !trackInfo?.versionCode ){
                    return 'green';
                }
                const versionMdmEqPs = Number(this.appVersionCode) === Number(trackInfo.versionCode);
                const versionMdmLwThPs = Number(this.appVersionCode) <= Number(trackInfo.versionCode);
                const versionPsIsProduction = trackInfo.trackAlias.toString().toLowerCase() === 'production';
                return (versionMdmLwThPs && versionPsIsProduction) || versionMdmEqPs ? 'green' : 'orange';
            },
            getStateError(){
                const code = this.playstoreLastStatus.error_msg?.code;
                const message = this.playstoreLastStatus.error_msg?.message;
                return code || message ?
                    (code ? "(" + code + ") " : "") + message : undefined;
            }
        }
    }

</script>

<style>

    .icon-cursor{
        cursor: pointer;
    }

    .icon-green {
        color: #7acf1f !important;
    }

    .a-decoration-none {
        text-decoration: none !important;
    }

    .icon-red {
        color: #ff5252 !important;
    }

    .icon-orange {
        color: #FF9800 !important;
    }

    .icon-brown {
        color: #964B00 !important;
    }

    .icon-undefined {
        color: var(--vtmn-color_grey-light-3) !important;
    }

    .tool-tip-multi-row {
        white-space: pre-line;
        height: auto;
    }

    .playstore-logo-container {
        display: flex;
        justify-content: center;
    }

</style>
