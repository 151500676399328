import Vue from "vue";
import {store} from "../store/store";
import {displayAlertDialog} from "../utils/utils";

const storeUtils = require('../utils/store-utils');

export default {

    async download(versionFilePath) {

        try {
            const base64FilePath = btoa(versionFilePath);
            const apiUrlToCall = `${store.state.urlDownloadVersion}`
                .replace("{country}", store.state.user.country?.toUpperCase())
                .replace("{store}", store.state.user.store)
                .replace("{device}", "undefined")
                .replace("{base64_file_path}", base64FilePath);
            const response = await Vue.http.get(apiUrlToCall);
            window.open(response.data?.data?.apkUrl, "Download");
        } catch (error) {
            displayAlertDialog(error.body?.message || error);
        }

    },
    extractFilePaths(versions) {
        let versionsFilepath = [];
        versions.forEach((version) => {
            if (version.filePath) {
                versionsFilepath.push(version.filePath);
            }
        });
        return versionsFilepath;
    },

    async getAvailableInformationOnVersion(versions) {
        const packageName = versions && versions.length > 0 ? versions[0].uid : undefined;

        if (!packageName) {
            const errorMessage = "No packageName found to retrieve the S3 availability information."
            console.error("getAvailableInformationOnVersion(): " + errorMessage);
            throw new Error(errorMessage);
        }

        const storeKey = "versionsAvailableS3";
        let versionsFilepath = this.extractFilePaths(versions);

        if (storeUtils.keyExists(storeKey, packageName)) {
            return Promise.resolve(storeUtils.get(storeKey, packageName));
        }

        try {
            const response = await Vue.http.post(`${store.state.urlApplicationApiCheckS3Availability}`, {
                s3FilePaths: versionsFilepath,
            });

            storeUtils.put(storeKey, packageName, response.body);
            return response.body;

        } catch (error) {
            console.error(error);
            throw error;
        }

    }
}
