<template>
    <div class="page-content full-size">
        <router-link to="/applications">
            <div id="navigation-back" class='page-title' @click="resetStoreIconsIfWebview"> < Back</div>
        </router-link>
        <div v-if='app'>
            <div class="content">
                <v-row class="margin-btm-10" align="center">
                    <v-col cols="4">
                        <v-row align="center">
                            <div v-if="app.icon != null">
                                <img v-if="app.icon != null" :src="app.icon.imageBase64 || app.icon"
                                     class='tall-app-icon'/>
                                <h1 id="applications-details-label-title" class='appTitle md-title'>{{ app.name }}</h1>
                            </div>
                            <v-skeleton-loader v-else type="card" :boilerplate="true" class="tall-app-icon"
                                               style="width:50%;"/>
                        </v-row>
                    </v-col>
                    <v-col cols="4" class="install-number">
                        <div><i class="ri-check-double-line msg-vertical-align"></i> UP TO DATE DEVICES : <span
                            id="applications-details-label-installed-devices">{{ displayUpToDateDevices }}</span></div>
                        <div><i class="ri-refresh-line msg-vertical-align"></i> DEVICES WAITING FOR UPDATE : <span
                            id="applications-details-label-non-installed-devices">{{
                                displayWaitingForUpdateDevices
                            }}</span></div>
                    </v-col>
                    <v-col cols="4" class="text-align-end">
                        <mdm-button id="applications-details-btn-edit" :text="'EDIT'"
                                    :tooltip-text="tooltipEditApplication"
                                    :icon="'edit'"
                                    :disable="grant('application', 'medium', app.userCountries, app.responsible) === 'button-disable'"
                                    @click='editProduct'/>

                        <mdm-button id="applications-details-btn-delete" :text="'DELETE'" :css="'md-raised md-accent'"
                                    :tooltip-text="tooltipDeleteApplication" :icon="'delete'"
                                    :disable="!( grant('application', 'medium', app.userCountries, app.responsible) !== 'button-disable' && countGroup === 0 && !isActivatedServicesGroupComputed)"
                                    @click="popinView='deleteApplication'"/>
                    </v-col>
                </v-row>

                <description-component :application="app"/>

                <div v-if="app.appType === appTypeEnum.PRIVATE_APP" class="first-column-tier">
                    <div class="column-app-detail">
                        <md-table v-model="searchedVersion" md-sort="version" md-sort-order="desc" md-card
                                  md-fixed-header>
                            <md-table-toolbar>
                                <h1 id="libelle-version" class="md-title">Versions : {{ countVersion }}</h1>
                                <md-field md-clearable class="md-toolbar-section-end">
                                    <md-input placeholder="Search a version..." v-model="searchVersion"
                                              @input="searchOnTableVersion"/>
                                </md-field>
                                <mdm-button :id="'mdm-button-add-version'"
                                            :css="'md-icon-button md-raised md-primary button-corner'"
                                            :tooltip-text="tooltipAddVersion" :icon="'add'"
                                            :disable="grant('application', 'medium', app.userCountries, app.responsible) === 'button-disable'"
                                            :to="{path :$route.params.id + '/addVersion',query: {appName: app.name, appUid : app.uid, hasAab : checkIfAab()  }}
                                                "/>
                            </md-table-toolbar>
                            <md-table-empty-state v-if="countVersion !== 0" md-label="No version found"
                                                  :md-description="`No version found for this '${searchVersion}' query. Try a different search term .`"></md-table-empty-state>
                            <md-table-empty-state v-else md-label="No version"
                                                  :md-description="`There is no version for this application.`"></md-table-empty-state>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell style="width: 20%;max-width: 120px;word-wrap: break-word;"
                                               md-label="Name"
                                               md-sort-by="version">
                                    <div>{{ item.version }}</div>
                                    <div class="version-time">{{ item.creationDate }}</div>
                                </md-table-cell>

                                <md-table-cell style="width: 5%;" md-label="Version code">
                                    {{ item.versionCode }}
                                </md-table-cell>

                                <md-table-cell style="width: 10%;font-size : 11px;" md-label="Size"
                                               md-sort-by="fileSize">{{
                                        item.fileSize| byteToString
                                    }}
                                </md-table-cell>

                                <md-table-cell style="width: 40%; font-size : 11px;" md-label="Description">{{
                                        item.versionDescription
                                    }}
                                </md-table-cell>

                                <md-table-cell style="width: 40%; white-space: nowrap" md-label="Action">

                                    <mdm-button
                                        :id="'mdm-button-download-version-'+(searchedVersion.length - searchedVersion.indexOf(item))"
                                        :css="'md-icon-button md-dense'"
                                        :tooltip-text="tooltipModifyVersion" :icon="'download'"
                                        :disable="grant('application', 'medium', app.userCountries, app.responsible) === 'button-disable'"
                                        @click="download(item)"
                                    />

                                    <mdm-button
                                        :id="'mdm-button-modify-version-'+(searchedVersion.length - searchedVersion.indexOf(item))"
                                        :css="'md-icon-button md-dense'"
                                        :tooltip-text="tooltipModifyVersion" :icon="'edit'"
                                        :disable="grant('application', 'medium', app.userCountries, app.responsible) === 'button-disable'"
                                        :to="{path : $route.params.id + '/addVersion?id=' + item.id,query: {appName: app.name, appUid : app.uid }}"/>

                                    <mdm-button
                                        :id="'mdm-button-delete-version-'+(searchedVersion.length - searchedVersion.indexOf(item))"
                                        :css="'md-icon-button md-accent md-dense'"
                                        @click="popinView = 'deleteVersion'; valueTmp = item.id"
                                        :tooltip-text="tooltipDeleteVersion" :icon="'delete'"
                                        :disable="isBiggest(item.versionCode) || grant('application', 'medium', app.userCountries, app.responsible) === 'button-disable'"/>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
                <div v-if="app.appType === appTypeEnum.PRIVATE_APP" class="charts column-app-detail second-column-tier">
                    <div style="background-color: #ffffff">
                        <h1 class="md-title" style="padding-left: 2%">REVIEWS</h1>
                        <div v-if='rate' class="review">
                            <star-rating read-only v-bind:increment="0.01" v-bind:round-start-rating="false"
                                         v-bind:fixed-points="2" v-model="rate.rating"
                                         v-bind:star-size="35"></star-rating>
                            <div>
                                <md-icon class="icon_count">person</md-icon>
                                {{ rate.rateCount }} total
                            </div>
                            <vue-chart
                                v-if='rate && rate.columns'
                                chart-type="google.charts.Bar"
                                :columns="rate.columns"
                                :rows="rate.rows"
                                :options="rate.options"
                            ></vue-chart>
                        </div>
                        <h1 class="md-title" style="padding-left: 2%">INSTALLED VERSIONS<span
                            class="caption font-italic"> [3 months]</span></h1>
                        <vue-chart
                            v-if='distribution && distribution.columns'
                            chart-type="PieChart"
                            :columns="distribution.columns"
                            :rows="distribution.rows"
                            :options="distribution.options"
                        ></vue-chart>
                    </div>
                </div>

                <div v-if="this.$store.state.group.group.countGroup > addGroupMaxLimit"
                     class="popin-actions total-width-div spaced-div visible-div">
                    <h1 class="popin-error-text large-div ">
                        {{
                            tooltipAddModifyGroupMaxLimit + $store.state.group.group.countGroup + " greater than " + addGroupMaxLimit
                        }}</h1>
                </div>

                <div>
                    <div v-if='this.$store.state.group.group.saveGroupLoader' class='overlay'/>
                    <md-progress-spinner class='loading' v-if="this.$store.state.group.group.saveGroupLoader"
                                         :md-diameter=100 :md-stroke=10 md-mode="indeterminate"/>
                </div>

                <md-progress-spinner v-if="Object.keys(app).length === 0" class='loading' :md-diameter=50 :md-stroke=5
                                     md-mode="indeterminate"/>

                <groups-table v-else-if="app.playstoreReady === 'DONE' || app.appType === appTypeEnum.PRIVATE_APP"
                              :app="app"
                              :android-track-ids="androidTrackIds"
                              :groups-are-refreshing="groupsAreRefreshing"
                              :grant="grant"
                              @deleteGroupPopin="updatePopinView"
                              @getGroups="checkIfServicesGroupIsActivated"
                />

                <v-alert v-else class="mt-1"
                         prominent
                         icon="warning"
                         type="warning">
                    <span>You cannot deploy your application on devices before the validation of our security team. Please wait the confirmation mail.</span>
                </v-alert>

                <div v-if="commentsCount !== 0" class="column-group-detail" style="height: 600px;overflow-y: scroll;">
                    <div class="md-layout md-gutter" style="float: right;">
                        <div class="md-layout-item">
                            <md-field>
                                <label>Version</label>
                                <md-select v-model="versionFilterValue" multiple>
                                    <md-option v-for="version in versionName" :value="version">{{ version }}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                    <h1 style="height: 80px" class="md-title">Users comments : {{ commentsCount }}</h1>
                    <div>
                        <md-list v-for="comments in comment" class="md-triple-line">
                            <md-list-item>
                                <div class="md-list-item-text">
							<span>
				          		{{ comments.user }}
                      <vue-flag :code=comments.country size='small'/>
				          		<span>: Store {{ comments.store }}</span>
				          		<span>{{ comments.version }}</span>
				          	</span>
                                    <span>
								<star-rating style="width: 20%;" read-only v-model="comments.rate" v-bind:padding="1"
                                             v-bind:inline=true v-bind:show-rating=false
                                             v-bind:star-size="15"></star-rating>
								<span>{{ comments.date }}</span>
							</span>
                                    <span>{{ comments.comment }}</span>
                                    <span>
                  <vue-flag :code="'gb'" size='small'/>
                  {{ comments.commentEN }}
                </span>
                                </div>
                            </md-list-item>
                        </md-list>
                    </div>
                </div>
            </div>
            <div v-if="popin != null" class="popins">
                <div class='popin-overlay' @click='popin = null'></div>
                <div v-if="popin == 'deleteApplication'" class="popin popin-delete">
                    <div class='popin-text'>Are you sure you want to remove this application?</div>
                    <div class='popin-delete-actions popin-actions'>
                        <md-button class="md-raised" @click="popinView = null">{{ FIELD_NO_CANCEL }}</md-button>
                        <md-button class="md-raised md-accent" @click='deleteApplication($route.params.id)'
                                   id="popup-delete-yes">
                            <md-icon>delete</md-icon>
                            {{ FIELD_YES_DELETE }}
                        </md-button>
                    </div>
                </div>
                <div v-if="popin == 'deleteVersion'" class="popin popin-delete">
                    <div class='popin-text'>Are you sure you want to remove this version?</div>
                    <div class='popin-delete-actions popin-actions'>
                        <md-button class="md-raised" @click="popinView = null">{{ FIELD_NO_CANCEL }}</md-button>
                        <md-button class="md-raised md-accent" @click='deleteVersion(valueTmp)' id="popup-delete-yes">
                            <md-icon>delete</md-icon>
                            {{ FIELD_YES_DELETE }}
                        </md-button>
                    </div>
                </div>
                <div v-if="popin == 'deleteGroup'" class="popin popin-delete">
                    <div class='popin-text'>Are you sure you want to remove this group?</div>
                    <div class='popin-delete-actions popin-actions'>
                        <md-button class="md-raised" @click="closePopinDeleteGroup()">{{ FIELD_NO_CANCEL }}</md-button>
                        <md-button class="md-raised md-accent"
                                   @click='deleteGroup(valueTmp)'
                                   id="popup-delete-yes"
                                   :disabled="deleteGroupErrorText">
                            <md-icon>delete</md-icon>
                            {{
                                deleteGroupYesButtonCounter !== null
                                && !isNaN(deleteGroupYesButtonCounter) ? FIELD_YES_DELETE + " (" +
                                    deleteGroupYesButtonCounter +
                                    ")" : FIELD_YES_DELETE
                            }}
                        </md-button>

                        <div v-if='loading' class='overlay'></div>
                        <md-progress-spinner v-if='loading' class='loading' :md-diameter="50" :md-stroke="10"
                                             md-mode="indeterminate"></md-progress-spinner>
                        <div v-if='deleteGroupErrorText' class='popin-error-text'>
                            {{ deleteGroupErrorText + ".\nPlease, retry later..." }}
                        </div>
                    </div>
                </div>
                <div v-if="popin === 'confirmUpdateGroup'" class="popin popin-delete">
                    <div class='popin-text'>The group has been updated</div>
                    <div class='popin-delete-actions popin-actions'>
                        <md-button id="applications-details-popin-btn-return-group-updated" class="md-raised"
                                   @click="closePopin(popin)">Return
                        </md-button>
                    </div>
                </div>
                <div v-if="popin === 'confirmUpdateVersion'" class="popin popin-delete">
                    <div class='popin-text'>The version has been saved</div>
                    <div class='popin-delete-actions popin-actions'>
                        <md-button class="md-raised" @click="closePopin(popin)">Return</md-button>
                    </div>
                </div>
                <div v-if="popin === 'unableDeleteVersion'" class="popin popin-delete">
                    <div class='popin-text'>Unable to remove this version</div>
                    <div class='popin-text'>Version used by a deployment group</div>
                    <div class='popin-delete-actions popin-actions'>
                        <md-button class="md-raised" @click="closePopin(popin)">Return</md-button>
                    </div>
                </div>
            </div>

        </div>
        <div v-else>
            <div class="content">
                <md-progress-spinner class='loading' :md-diameter="100" :md-stroke="10"
                                     md-mode="indeterminate"></md-progress-spinner>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import GrantUtils from "../../../components/Grant/grant";
import Moment from "moment";
import DescriptionComponent from "./description";
import VersionService from "../../../service/version-service";
import AndroidApiService from "../../../service/devices/android-api-service";
import ApplicationService from "../../../service/application/application-service";
import applicationService from "../../../service/application/application-service";
import FieldConstant from "../../../utils/field-constant";
import appTypeEnum from "../../../service/application/app-type-enum";
import GroupsTable from "../../../components/applications/deploymentGroupsTable/groupsTable";
import UpToDateColumn from "./group/up-to-date-column";
import DistributionChartUtils from '../../../utils/store/distribution-chart-utils';
import DeploymentGroupConstant from "../../../constant/deployment-group-constant";

function toLower(text) {
    if (text) {
        return text.toString().toLowerCase();
    }
    return "";
}

const searchByVersion = (items, term) => {
    if (term) {
        return items.filter(item => toLower(item.version).includes(toLower(term)));
    }
    return items
}
export default {
    components: {FieldConstant, DescriptionComponent, GroupsTable, UpToDateColumn},
    data() {
        return {
            addGroupMaxLimit: 10,
            appUid: '',
            appTypeEnum,
            commentsCount: 0,
            biggestVersion: 0,
            countVersion: 0,
            searchedVersion: [],
            searchVersion: null,
            versionFilterValue: [],
            versionName: [],
            allowApplicationDelete: true,
            waitingToInstall: '',
            installed: '',
            valueTmp: 0,
            popinView: '',
            loading: false,
            tooltipAddModifyGroupMaxLimit: this.$store.state.labels.GROUP_ADD_MODIFY_IMPOSSIBLE_MAX_LIMIT,
            tooltipDeleteProcessingGroup: this.$store.state.labels.GROUP_DELETE_PROCESSING_IMPOSSIBLE,
            tooltipEditApplication: this.$store.state.labels.APPLICATION_EDIT_IMPOSSIBLE,
            tooltipDeleteApplication: this.$store.state.labels.APPLICATION_DELETE_IMPOSSIBLE,
            tooltipAddVersion: this.$store.state.labels.VERSION_ADD_IMPOSSIBLE,
            tooltipModifyVersion: this.$store.state.labels.VERSION_MODIFY_IMPOSSIBLE,
            tooltipDeleteVersion: this.$store.state.labels.VERSION_DELETE_IMPOSSIBLE,
            deleteGroupErrorText: null,
            deleteGroupYesButtonCounter: null,
            FIELD_YES_DELETE: FieldConstant.YES_DELETE,
            FIELD_NO_CANCEL: FieldConstant.NO_CANCEL,
            CONSTANT: FieldConstant,
            app: {},
            groups: [],
            androidTrackIds: null,
            androidTrackIdsLoadedOneTime: false,
            groupsLoadedOneTime: false,
            groupsAreRefreshing: false,
            GROUPS_AND_TRACKS_REFRESH_DELAY: 15000,
            PLAYSTORE_STATUS: FieldConstant.GROUP.PLAYSTORE_STATUS,
            trackInterval: null, statusInterval: null,
            isActivatedServicesGroup: false
        }
    },
    mounted: function () {
        this.$store.commit('application/resetDevicesWithInstalledApp');
        this.$store.commit('application/resetTotalTargetedDevices');
        this.$store.commit('application/resetUpToDateDevicesByVersionName');
        this.loadApp();
    },
    destroyed: function () {
        if (this.trackInterval) {
            clearInterval(this.trackInterval);
        }
        if (this.statusInterval) {
            clearInterval(this.statusInterval);
        }
    },
    computed: {
        distribution() {
            return DistributionChartUtils.buildChartFromStoreData(this.$store.state, this.appUid);
        },
        displayUpToDateDevices() {
            return this.$store.state.application.upToDateDevices;
        },
        displayWaitingForUpdateDevices() {
            return this.$store.state.application.totalTargetedDevices - this.$store.state.application.upToDateDevices;
        },
        popin: function () {
            if (this.popinView) {
                return this.popinView;
            } else if (this.popinView == null) {
                return null;
            } else if (this.$route.query.confirm) {
                return this.$route.query.confirm;
            }
            return null;
        },
        countGroup() {
            return this.$store.state.group.group.countGroup;
        },
        isActivatedServicesGroupComputed() {
            return this.isActivatedServicesGroup
        },
        ApplicationRate: function () {
            if (!this.appUid) return;

            return {app: this.appUid};
        },
        ApplicationComments: function () {
            if (!this.appUid) return;

            const version = this.versionFilterValue.length === 0 ? null : this.versionFilterValue.toString();

            return {
                app: this.appUid,
                appVersions: version,
                pagination: {page: 1, size: 50}
            };
        }
    },
    asyncComputed: {
        comment: function () {
            if (!this.ApplicationComments) return Promise.resolve([]);

            return Vue.http.post(`${this.$store.state.urlAdminRate}/comments`, this.ApplicationComments).then((response) => {
                    if (response.body.data.dataComments !== null && response.body.data.dataComments.count !== null) {
                        this.commentsCount = response.body.data.dataComments.count;
                        const commentsData = response.body.data.dataComments.ratings;
                        let comments = [];
                        const versionsComments = [];
                        commentsData.forEach(function (el) {
                            if (versionsComments.indexOf(el.appVersion) === -1) {
                                versionsComments.push(el.appVersion);
                            }
                            comments.push({
                                'date': Moment.unix(el.eventTime / 1000).format('DD/MM/YYYY'),
                                'user': el.user,
                                'store': el.store,
                                'country': toLower(el.country),
                                'rate': el.value,
                                'comment': el.comments,
                                'commentEN': el.commentsAutoEn,
                                'version': el.appVersion
                            });

                        })
                        if (this.versionName.length === 0) {
                            let versiontoSort = versionsComments.sort()
                            this.versionName = versiontoSort.reverse();
                        }
                        return comments;
                    }
                },
                (error) => {
                    console.log(error);
                });
        },
        rate: function () {
            if (!this.ApplicationRate) return Promise.resolve({});

            return Vue.http.post(`${this.$store.state.urlAdminRate}/statistics`, this.ApplicationRate).then((response) => {
                    if (response.body.data.datas != null && response.body.data.datas.summaryApp != null && response.body.data.datas.aggregationSimple != null) {
                        const rate = response.body.data.datas.summaryApp;
                        const rateAggregate = response.body.data.datas.aggregationSimple;
                        let infos = [];
                        rateAggregate.forEach(function (el) {
                            let rate = [];
                            rate.push(el.key);
                            rate.push(el.doc_count);
                            infos.push(rate)
                        });
                        return {
                            'rating': rate.rating,
                            'rateCount': rate.doc_count,
                            columns: [
                                {
                                    'type': 'string',
                                },
                                {
                                    'type': 'number',
                                }
                            ],
                            rows: infos,
                            options: {
                                legend: {position: "none"},
                                color: ['#B3E5FC', '#81D4FA', '#4FC3F7', '#29B6F6', '#03A9F4'],
                                bars: 'horizontal',
                                height: 145,
                                width: 310,
                                backgroundColor: {
                                    fill: '#ffffff'
                                }
                            }
                        }
                    } else {
                        return {
                            'rating': 0,
                            'rateCount': 0,
                            columns: [
                                {
                                    'type': 'string',
                                },
                                {
                                    'type': 'number',
                                }
                            ],
                            rows: [["5", 0], ["4", 0], ["3", 0], ["2", 0], ["1", 0]],
                            options: {
                                legend: {position: "none"},
                                bars: 'horizontal',
                                height: 145,
                                width: 310,
                                backgroundColor: {
                                    fill: '#ffffff'
                                }
                            }
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    return {
                        'rating': 0.0,
                        'rateCount': 0,
                    };
                });
        }
    },
    methods: {
        resetStoreIconsIfWebview() {
            if (this.app.appType === FieldConstant.APPLICATION.WEBVIEW) this.$store.commit('icons/resetIconsList');
        },

        checkIfServicesGroupIsActivated(groups) {
            this.isActivatedServicesGroup = (groups.find(group => group.bu === DeploymentGroupConstant.BU.SERVICES))?.activated;
        },
        async download(version) {
            await VersionService.download(version.filePath);
        },
        checkIfAab() {
            const hasAab = this.searchedVersion.some(version => version.filePath && version.filePath.includes('.aab'));
            return hasAab.toString();
        },
        loadApp: function () {
            return Vue.http.get(this.$store.state.getUrlWithParams(this.$store.state.urlApplicationIdOrPackageName, {idOrPackageName: this.$route.params.id})).then(async (response) => {

                    localStorage.setItem('current_app_name', response.body.name);
                    const app = response.body;
                    this.appUid = app.uid;
                    const self = this;

                    if (app.appType === appTypeEnum.PUBLIC_APP || app.appType === appTypeEnum.WEBVIEW) {
                        this.countVersionsAvailable = 1;
                        this.app = await ApplicationService.convertAppToPrintableApp(app).then(applications => applications);
                        return;
                    }
                    let versionsArray = [];
                    let versionName = [];

                    this.countVersion = response.body.versions.length;

                    const icon = this.countVersion > 0 ? (await applicationService.getApplicationIcon(app.versions[0].uid)).imageBase64 : '';

                    for (const version of app.versions) {
                        if (version.versionCode > self.biggestVersion) {
                            self.biggestVersion = version.versionCode;
                        }
                        versionName.push(version.versionName);
                        let versionDescription = [];
                        if (version.versionDescription != null) {
                            versionDescription = version.versionDescription[0].description.join();
                        }
                        versionsArray.push({
                            applicationID: version.applicationID,
                            versionDescription: versionDescription,
                            creationDate: Moment(version.creationDate).format('DD/MM/YYYY'),
                            eligibility: version.eligibility,
                            file: version.file,
                            filePath: version.filePath,
                            fileSize: version.fileSize,
                            icon,
                            id: version.id,
                            uid: version.uid,
                            version: version.versionName,
                            versionCode: version.versionCode,
                        });
                    }

                    this.app = await ApplicationService.convertAppToPrintableApp(app, versionsArray).then(appToReturn => {
                        this.searchedVersion = appToReturn.versions;

                        this.countVersionsAvailable = 0;
                        // get the s3 (europe / china info)
                        VersionService.getAvailableInformationOnVersion(appToReturn.versions)
                            .then((newVersion) => {
                                setTimeout(() => {

                                    this.countVersionsAvailable = newVersion.filter(v => v?.exists).length;
                                    console.log("countVersionsAvailable =" + this.countVersionsAvailable);

                                }, 2)
                            })
                            .catch((error) => console.error("A problem occurred when the version available loading", error));

                        self.loadTrackIds(app.uid);
                        return appToReturn;
                    });
                    this.app.playstoreEventStatus = await ApplicationService.getPlaystoreLastStatusByTypeAndId("APPLICATION", app.id);
                }, (error) => {
                    this.loading = false;
                    console.log(error);
                    if (error.status !== 200) {
                        this.$router.push('/applications');
                    }
                }
            );
        },

        async launchLoadTrackIdsWithIntervalIfNeeded() {
            if (this.androidTrackIdsLoadedOneTime) {
                return;
            }
            this.androidTrackIdsLoadedOneTime = true;

            this.trackInterval = setInterval(async () => {
                if (!this.$refs.groupAndroidTrackButtonRef) {
                    if (this.$store.state.group.group.countGroup === 0) return await this.loadTrackIds(this.appUid);
                    else return clearInterval(this.trackInterval);
                }
                const nbPlaystoreEventsInProgressOrNone = this.$refs.groupAndroidTrackButtonRef.filter(g =>
                    g.playstoreLastStatusLoaded &&
                    (g.playstoreLastStatus?.state === this.PLAYSTORE_STATUS.IN_PROGRESS
                        || g.playstoreLastStatus?.state === this.PLAYSTORE_STATUS.NONE)).length;

                if (nbPlaystoreEventsInProgressOrNone > 0) {
                    await this.loadTrackIds(this.appUid);
                } else {
                    clearInterval(this.trackInterval);
                }
            }, this.GROUPS_AND_TRACKS_REFRESH_DELAY);

        },
        loadTrackIds: async function (appUid) {
            AndroidApiService.getApplicationTracks(appUid).then(app => {
                this.androidTrackIds = app || []
                this.launchLoadTrackIdsWithIntervalIfNeeded(appUid);
            })
        },
        searchOnTableVersion() {
            this.searchedVersion = searchByVersion(this.app.versions, this.searchVersion);
        },
        editProduct: function () {
            if (this.app.appType === "WEBVIEW") {
                this.$router.push({
                    'path': `/applications/pwa/information/${this.app.id}`
                });
            } else {
                document.location.href += '/edit';
            }
        },
        deleteVersion: function (id) {
            return Vue.http.delete(
                this.$store.state.getUrlWithParams(
                    this.$store.state.urlVersionId,
                    {
                        idVersion: id
                    })).then((response) => {
                if (!response.body.success) {
                    this.popinView = 'unableDeleteVersion';
                } else {
                    this.$ga.event({eventCategory: 'version', eventAction: 'delete', eventLabel: id});
                    location.reload();
                    this.popin = null;
                }
            }, (error) => {
                console.log(error);
                this.popin = null;
            });
        },
        isBiggest: function (versionCode) {
            return versionCode === this.biggestVersion;
        },
        closePopinDeleteGroup: function () {
            this.loading = false;
            this.popinView = null;
            this.deleteGroupErrorText = null;
            this.deleteGroupYesButtonCounter = null;
        },
        deleteGroup: function (id) {
            this.deleteGroupErrorText = null;
            this.loading = true;
            let formData = new FormData();
            formData.append('groupId', id);

            return Vue.http.delete(this.$store.state.getUrlWithParams(this.$store.state.urlGroupDelete, {idGroup: id}), formData).then((response) => {
                this.$ga.event({eventCategory: 'group', eventAction: 'delete', eventLabel: id});

                if (this.$store.state.group.group.countGroup > 2) {
                    this.$router.push({
                        'path': `/applications/${this.$route.params.id}/groupPriority`,
                        'query': {
                            'confirm': 'DeleteGroup'
                        }
                    });
                } else {
                    location.reload();
                    this.popinView = null;
                }
                this.loading = false;
            }, (error) => {
                this.loading = false;
                this.deleteGroupErrorText = error.bodyText ? error.bodyText : "An error occurred while deleting the group " + id;
                this.startDeleteGroupYesButtonPopinTimer();
            });
        },
        startDeleteGroupYesButtonPopinTimer: function () {
            this.deleteGroupYesButtonCounter = 10;
            const countDown = setInterval(() => {
                this.deleteGroupYesButtonCounter = this.deleteGroupYesButtonCounter - 1;
                if (this.deleteGroupYesButtonCounter <= 0 || this.deleteGroupYesButtonCounter == null) {
                    this.deleteGroupYesButtonCounter = null;
                    this.deleteGroupErrorText = null;
                    clearInterval(countDown);
                }
            }, 1000);
        },
        async deleteApplication(id) {
            try {
                await Vue.http.delete(this.$store.state.getUrlWithParams(
                    this.$store.state.urlApplicationIdOrPackageName,
                    {idOrPackageName: id}
                ));

                this.$ga.event({eventCategory: 'application', eventAction: 'delete', eventLabel: id});
                this.$router.push('/applications');
            } catch (error) {
                console.error(error);
            }
        },

        grant: function (category, risk, countries, elUid) {
            let uid = '***';
            if (elUid) {
                uid = elUid;
            }
            return GrantUtils.grant(this.$store.state,
                {
                    category,
                    risk,
                    countries,
                    uid
                }
            )
        },
        closePopin: function (popin) {
            document.getElementById('feedback').click();
            this.popinView = null;
            document.location.href = document.location.href.replace('?confirm=' + popin, '');
        },
        updatePopinView(text, groupId) {
            this.popinView = text;
            this.valueTmp = groupId;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/color';

.md-table-cell-container > div {
    width: fit-content;
    margin: auto;
}

.md-field {
    max-width: 300px;
    width: 300px;
    right: 250px;
}

.tall-app-icon {
    max-width: 125px;
    width: 50%;
    vertical-align: middle;
    margin-bottom: 1%
}

.install-number {
    font-weight: bold;
}

.content {
    padding: 30px 10px;
}

.md-list {
    width: 48%;
    max-width: 100%;
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(#000, .12);
    border-radius: 5px;
    margin: 1%;
}

.md-list-item-text * {
    overflow: auto !important;
    text-overflow: unset;
}

.summary-actions {
    float: right;
}

.information-item:last-child {
    margin-bottom: 0px;
}

.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    z-index: 11;
}

.loading-group {
    position: relative;
    width: 25px;
    z-index: 11;
    margin-left: 10px;
}

</style>
<style lang="scss">
@import './details';
</style>

